import React, { Component } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'
import Container from '../components/Container/Container'
import Seo from '../components/Seo/Seo'
import Form from '../components/Form/Form'
import Post from '../components/Post/Post'

import Try from '../sections/Try/Try'

class BlogPage extends Component {
    state = {
        posts: [],
        visiblePosts: 9
    }

    componentDidMount() {
        const { edges } = this.props.data.allMarkdownRemark

        this.setState({
            posts: edges
        })
    }

    showMore = () => {
        this.setState(prevState => ({
            visiblePosts: prevState.visiblePosts + 2
        }))
    }

    render() {
        const { posts, visiblePosts } = this.state

        const loadMoreButton = (
            <div className="col-xs-12">
                <button className="posts__btn" onClick={this.showMore}>
                    LOAD MORE
        </button>
            </div>
        )

        return (
            <Layout pageName="blog">
                <Seo />
                <Container>
                    <section className="hero">
                        <h1 className="heading heading--big">Team visibility at Scale</h1>
                        <p className="hero__subheading">
                            {'/'}* Notes from Ally team. <a href="/">Join the team.</a> *{'/'}
                        </p>
                    </section>

                    <section className="subscribe">
                        <Form />
                    </section>

                    <section className="posts row middle-sm">
                        {posts.map((edge, i) => {
                            if (i < visiblePosts) {
                                const { frontmatter } = edge.node

                                return (
                                    <Post key={frontmatter.slug} index={i} {...frontmatter} />
                                )
                            }
                            return null
                        })}

                        {posts.length > visiblePosts && loadMoreButton}
                    </section>

                    <Try />
                </Container>
            </Layout>
        )
    }
}

export const query = graphql`
  query BlogPageQuery {
    allMarkdownRemark(sort: { order: ASC, fields: [frontmatter___date] }) {
      edges {
        node {
          frontmatter {
            slug
            date
            title
            author
            description
          }
        }
      }
    }
  }
`

export default BlogPage
