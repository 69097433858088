import React from 'react'
import { Link } from 'gatsby'
import Image from '../../components/Image/Image'

const Post = ({ index, slug, title, author, date, description }) => (
  <div
    className={`${
      index % 2 === 0
        ? 'post col-xs-12 col-sm-5'
        : 'post post--right col-xs-12 col-sm-5 col-sm-offset-2 '
    }`}
  >
    <Image
      className="post__author-image"
      filename={author
        .toLowerCase()
        .split(' ')
        .join('-')}
    />
    <p className="post__title">
      <Link to={`/blog/${slug}`}>{title}</Link>
    </p>
    <p className="post__info">
      {author} <span className="date">{date}</span>
    </p>
    <p className="post__description extra-small">{description}</p>
  </div>
)

export default Post
